<template>
  <div>
    <div class="typebox" v-for="(item, index) in categoryList" :key="index">
      <div class="typebox-row">
        <div class="type-title">{{ item.text }}</div>
        <div class="type-name">
          <div
            :class="[
              isActive3 == index2 && outsideIndex == index && isselect
                ? 'type-name-font'
                : ' type-name-font2',
            ]"
            v-for="(item2, index2) in item.children"
            :key="index2"
          >
            <div
              class="two"
              :style="{
                color:
                  isActive3 == index2 && outsideIndex == index && isselect
                    ? '#FFFFFF'
                    : '#333333',
              }"
              @click="select3(index2, index, item.children)"
            >
              <div>{{ item2.text }}</div>
              <!-- <div class="therr" :style="{display:isActive3==index2&&outsideIndex==index?'block':'none'}"> -->
            </div>
            <div
              :class="[
                isActive3 == index2 && outsideIndex == index && isselect
                  ? 'therr2'
                  : ' therr',
              ]"
            >
              <div class="lalala" v-if="!islist">
                <router-link
                  class="content"
                  v-for="(item3, index3) in item2.children"
                  :key="index3"
                  :to="'/PCshop/goods/list?categoryId=' + item3.id"
                >
                  <div
                    class="therr-item"
                    @mousemove="select4(index3, index2)"
                    :style="{
                      color: isActive4 == index3 ? '#FC4B24' : '#333333',
                    }"
                  >
                    {{ item3.text }}
                  </div>
                </router-link>
              </div>
              <div class="lalala" v-else>
                <div
                  class="content"
                  v-for="(item3, index3) in item2.children"
                  :key="index3"
                >
                  <div class="therr-item" @click="list(item3.id)">
                    {{ item3.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { queryGoodsCategoryTree, qeryShopGoodsList } from "@/api/shop";
export default {
  props: { islist: Boolean },
  data () {
    return {
      categoryList: [],
      isActive3: 100,
      isActive4: 100,
      outsideIndex: 0,
      categoryId: 0,
      list1: [],
      isselect: false
    }
  },
  created () {
    this.postQeryShopGoodsCategoryList()
  },
  methods: {
    select3 (idx, outsideIndex, arr) {
      console.log(arr, 89)
      this.isActive3 = idx
      this.outsideIndex = outsideIndex;
      this.isselect = !this.isselect
    },
    select4 (idx) {
      this.isActive4 = idx
    },
    //分类列表数据
    async postQeryShopGoodsCategoryList () {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response.slice(0, 6);
      console.log(this.categoryList, 87879879)
    },
    async list (item) {
      this.categoryId = item
      await this.postQeryShopGoodsList();
      this.$emit("list1", this.list1);
    },
    //请求搜索数据
    async postQeryShopGoodsList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.IsShelf = 1;
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.CategoryIds = [this.categoryId + ''];
      parm.minPrice = this.minPrice;
      parm.maxPrice = this.maxPrice;
      parm.maxPoints = this.maxPoints;
      parm.minPoints = this.minPoints;
      parm.keyWords = this.value;
      parm.sortType = this.sortType;
      parm.sortField = this.sortField;
      const res = await qeryShopGoodsList(parm);
      // console.log(res, 888888888);
      this.total = res.dataCount; //总数
      // this.goodsRecommendList = res.data;
      this.list1 = res.data;
      // console.log(this.list1, "list1");
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
  }
}
</script>
<style lang='scss' scoped>
.typebox {
  width: 1100px;
  margin: 0 auto;
}
.typebox-row {
  display: flex;
  .type-title {
    width: 110px;
    height: 36px;
    background: #efefef;
    font-size: 12px;
    color: #999999;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 15px;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
  }
  .type-name {
    position: relative;
    .two {
      margin: 0 auto;
      cursor: pointer;
      line-height: 35px;
    }
    flex: 1;
    background: #ffffff;
    height: 36px;
    display: flex;
    border: 1px solid #e7e7e7;
    .type-name-font {
      background-image: url("../../../assets/ShopImg/caidanbottom.png");
      background-repeat: no-repeat;
      background-size: 7px 4px;
      background-position: 54px 14px;
      width: 72px;
      height: 35px;
      background-color: #f6462b;
      color: #ffffff;
      display: flex;
      font-size: 12px;
      text-align: center;
      .text2 {
        cursor: pointer;
        color: #333333;
      }
      .text3 {
        cursor: pointer;
        color: red;
      }
    }
    .type-name-font2 {
      width: 72px;
      height: 35px;
      background: #ffffff;
      color: #333333;
      display: flex;
      font-size: 12px;
      text-align: center;
      .text2 {
        cursor: pointer;
        color: #333333;
      }
      .text3 {
        cursor: pointer;
        color: red;
      }
    }
  }
}
.therr {
  display: none;
}
.therr2 {
  position: absolute;
  background: #ffffff;
  width: 111%;
  top: 34px;
  left: -108px;
  z-index: 999;
  height: 36px;
  border-top: 1px solid #eaeaea;
  box-shadow: 4px 6px 10px rgba(110, 110, 110, 0.2);
  .lalala {
    display: flex;
    .therr-item {
      cursor: pointer;
      font-size: 12px;
      color: #333333;
      font-weight: 400;
      margin-right: 40px;
      padding: 10px 0px 10px 20px;
      box-sizing: border-box;
    }
  }
}
</style>