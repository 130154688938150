var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.categoryList),function(item,index){return _c('div',{key:index,staticClass:"typebox"},[_c('div',{staticClass:"typebox-row"},[_c('div',{staticClass:"type-title"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"type-name"},_vm._l((item.children),function(item2,index2){return _c('div',{key:index2,class:[
            _vm.isActive3 == index2 && _vm.outsideIndex == index && _vm.isselect
              ? 'type-name-font'
              : ' type-name-font2' ]},[_c('div',{staticClass:"two",style:({
              color:
                _vm.isActive3 == index2 && _vm.outsideIndex == index && _vm.isselect
                  ? '#FFFFFF'
                  : '#333333',
            }),on:{"click":function($event){return _vm.select3(index2, index, item.children)}}},[_c('div',[_vm._v(_vm._s(item2.text))])]),_c('div',{class:[
              _vm.isActive3 == index2 && _vm.outsideIndex == index && _vm.isselect
                ? 'therr2'
                : ' therr' ]},[(!_vm.islist)?_c('div',{staticClass:"lalala"},_vm._l((item2.children),function(item3,index3){return _c('router-link',{key:index3,staticClass:"content",attrs:{"to":'/PCshop/goods/list?categoryId=' + item3.id}},[_c('div',{staticClass:"therr-item",style:({
                    color: _vm.isActive4 == index3 ? '#FC4B24' : '#333333',
                  }),on:{"mousemove":function($event){return _vm.select4(index3, index2)}}},[_vm._v(" "+_vm._s(item3.text)+" ")])])}),1):_c('div',{staticClass:"lalala"},_vm._l((item2.children),function(item3,index3){return _c('div',{key:index3,staticClass:"content"},[_c('div',{staticClass:"therr-item",on:{"click":function($event){return _vm.list(item3.id)}}},[_vm._v(" "+_vm._s(item3.text)+" ")])])}),0)])])}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }